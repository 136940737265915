body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-root,.ant-layout-sider{
  background:#f5f5f5!important; /* #424b55 */
}

.ant-layout-header {
  margin-left: 30px;
}

a.fhlink{
  color:#ff4d4f ;
}

.ant-menu .ant-menu-item .ant-menu-item-icon, .ant-menu .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-submenu, .ant-menu-sub {
  color: #747474;
}

.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #eeeeee
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, .ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, .ant-menu-submenu:hover {
  background-color:#eeeeee;
}

span.ant-menu-title-content {
  color: #747474;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, .ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background: #f5f5f5;
}

.ant-menu .ant-menu-submenu-arrow {
  color: #000;
}