:root {
	--primary-color: rgb(255,0,0);
	--primary-color-hover:rgb(200,0,0);
  }

body{
	
	background: #cfcece;
}

div.container{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

div.contentCard{
	width: 400px;
	padding: 20px
}



.logo{
	width:250px;
	height:100px;
	padding: 8px;
	background:  url('../images/logo.png');
	background-repeat: no-repeat;
    background-size: contain;
}

.chakra-form-control{
	margin-bottom: 15px!important;
}
.css-17ba83t{
	margin-bottom: 20px;
	/*background: none!important;*/
	/*border: 1px solid var(--primary-color);*/
	border-radius: 5px;
	/*color: var(--primary-color);*/

}

.layout{
	height: 80vh;
}

.login button{
	width: 100%;
}

.footer{
	font-size: 10px;
	color: lightslategray;
	border-top: 1px solid var(--chakra-colors-chakra-border-color);
	text-align: left;
	padding-top: 16px;

}
.footer > p{
  margin-top: 10px;
}