.marginAlignment {
    width: 10%;
    margin: 24px;
}

.cardAlignment {
    margin: 24px 16px;
    padding: 5;
    min-height: 5;
    text-align: center;
}

.formItemInline {
    padding: 5px;
    vertical-align: top;
}

.modalClass .ant-modal-footer button {
    width: 30%;
}

.tableContainer {
    overflow-x: auto;
}

.responsiveTableData {
    display: none;
    font-weight: bold;
    margin-right: 10px;
}

.responsiveInline {
    display:inline
}

@media (max-width: 768px) {
    .responsiveTableData {
        display: inline;
    }

    .ant-table-thead {
        display: none;
    }

    .ant-table-tbody>tr>td {
        display: flex;
    }
}


.menu-logo {
    height: 100px;
    padding: 8px;
    background: url('../images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    margin: 16px 16px -60px 16px
}

.ant-layout-sider-collapsed .menu-logo{
    height: 80px!important;
    background-position-x: left;
    background-size: 256px 68px !important;
    margin-bottom: 0;
}

.main-container {
    padding: 16px 16px 40px 16px;
   
}

.address-details-label {
    font-weight: bold;
}

.address-details-item {
    display: block;
    margin-top: -5px;
}

.address-details-value {
    margin-top: -14px;
}

.listHeader>*{
    display: inline;
    margin-right: 10px;
}

.errorpage-content{
    background:#fff;
    width:100%;
    height:100vh;
}

.filterFormContainer{
    background: #fff;
    margin-left: 30px;
   
}

.filterForm{
    
    margin: -20px 0 20px -15px!important;
    transition: visibility 0.5s, opacity 0.5s linear, height 0.5s linear;
    width: calc(100% + 30px)
}

.fold{
    visibility: hidden;
    opacity: 0;
    height: 0px;
}
.collapsed{
    height:100%;
    visibility: visible;
    opacity:1;
   
}

